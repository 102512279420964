import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import {
  IonicFooterModule,
  MerchFooterModule,
  RestaurantsFooterModule,
  ShopperFooterModule,
  AnvogueFooterModule,
} from '@storefront/ng.themes';
import { SubscribeFormModule } from '../subscribe-form/subscribe-form.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    SharedModule,
    IonicFooterModule,
    MerchFooterModule,
    ShopperFooterModule,
    RestaurantsFooterModule,
    SubscribeFormModule,
    AnvogueFooterModule
  ],
  exports: [FooterComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class FooterModule {}
