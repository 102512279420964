import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeFormComponent } from './subscribe-form.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import {
  DefaultSubscribeFormModule,
  MerchSubscribeFormModule,
  RestaurantsSubscribeFormModule,
  ShopperSubscribeFormModule,
  AnvogueSubscribeFormModule
} from '@storefront/ng.themes';

@NgModule({
  declarations: [SubscribeFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    DefaultSubscribeFormModule,
    MerchSubscribeFormModule,
    ShopperSubscribeFormModule,
    RestaurantsSubscribeFormModule,
    AnvogueSubscribeFormModule,
  ],
  exports: [SubscribeFormComponent],
})
export class SubscribeFormModule {}
