<!-- ====== anvogue Item Details Start -->
<lib-anvogue-item-quick-view *ngIf="currentTheme == 'anvogue'" [anvogueDesignOptions]="anvogueDesignOptions"
  [anvogueConfiguration]="anvogueConfiguration" [translations]="translations" [isRtl]="isRtl" [loading]="loading"
  [defaultImage]="defaultImage" [images]="images" [item]="item" [itemDetail]="itemDetail"
  (closeQuickView)="closeQuickView()" [addOrRemoveFavoriteLoading]="favoriteAppService.addOrRemoveFavoriteLoading"
  [currentLang]="currentLang" [priceBasedOnPriceListQuantity]="priceBasedOnPriceListQuantity"
  [isCurrentAfter]="isCurrnetAfter" [isCurrentBefore]="isCurrnetBefore"
  [disableIncreaseQuantityBtn]="disableIncreaseQuantityBtn" [disableDecreaseQuantityBtn]="disableDecreaseQuantityBtn"
  [currentSymbol]="currnetSymbol"
  [itemPrice]="!hasPriceList?displayCurrency(itemDetail?.price):displayCurrency(priceBasedOnPriceListQuantity)"
  [priceRange]="priceRange" [priceRangeMin]="displayCurrency(priceRange?.min)"
  [priceRangeMax]="displayCurrency(priceRange?.max)"
  [itemBrandName]="getValue(storeAppService.getBrandById(item?.brandId))"
  [itemBrandUrl]="entityUtilityService.getBrandUrl(storeAppService.getBrandById(item?.brandId))?.toString()"
  [categories]="categories" [quantity]="quantity" [addingToCart]="addingToCart"
  [isItemHasMultiVariants]="isItemHasMultiVariants(item?.itemDetails)"
  [displayCurrencyFunc]="displayCurrency.bind(this)" [option1Id]="option1Id" [option2Id]="option2Id"
  [option3Id]="option3Id" (addOrRemoveFavorite)="addOrRemoveFavorite()" (sendInquiry)="sendInquiry()"
  (increaseQuantity)="increaseQuantity()" (decreaseQuantity)="decreaseQuantity()"
  (onUpdateQuantity)="onUpdateQuantity($event)" (addToCart)="addToCart()" (onChangeVariant)="onChangeVariant()">
  <ng-template #loadingPage>
    <app-loading></app-loading>
  </ng-template>
</lib-anvogue-item-quick-view>


