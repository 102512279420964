import { Component, EventEmitter, Injector } from '@angular/core';
import {
  FeaturesHomePosition,
  NewsletterHomePosition,
  StoreConfigPaymentTypesDto,
  StorePageStoreDto,
  StorePageType,
} from '@tajer/api';
import {
  AnvogueFooterConfiguration,
  AnvogueFooterDesignOptions,
  IonicFooterConfiguration,
  IonicFooterDesignOptions,
  MerchFooterConfiguration,
  MerchFooterDesignOptions,
  RestaurantsFooterConfiguration,
  RestaurantsFooterDesignOptions,
  ShopperFooterConfiguration,
  ShopperFooterDesignOptions,
} from '@storefront/ng.themes';
import { BaseComponent } from 'src/app/shared/components/base.component';
import {
  CustomCategoryStoreDto,
  CustomStorePageStoreDto,
} from '@storefront/ng.themes/lib/models';
import { CustomerServiceAppService } from 'src/app/shared/app-services/customer-service-app.service';
import { AddressAppService } from 'src/app/shared/app-services/address-app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BaseComponent {
  //#region variables

  iosImageUrl = 'assets/app-store.png';
  androidImageUrl = 'assets/google-play.png';

  public get color(): string {
    return (
      this.storeDesignOptions?.footerColor ?? this.storeDesignOptions?.color
    );
  }
  public get frontColor(): string {
    return (
      this.storeDesignOptions?.footerFrontColor ??
      this.storeDesignOptions?.frontColor
    );
  }
  public get footerHeadingColor(): string {
    return (
      this.storeDesignOptions?.footerHeadingColor ??
      this.storeDesignOptions?.color
    );
  }
  public get footerIconsColor(): string {
    return (
      this.storeDesignOptions?.footerIconsColor ??
      this.storeDesignOptions?.frontColor
    );
  }

  public get newsLetterButtonColor(): string {
    return (
      this.storeDesignOptions?.newsLetterButtonColor ??
      this.storeDesignOptions?.frontColor
    );
  }
  newsletterHomePosition = NewsletterHomePosition;
  featuresHomePosition = FeaturesHomePosition;

  desginOptions: IonicFooterDesignOptions;
  configuration: IonicFooterConfiguration;

  //merch theme
  merchDesginOptions: MerchFooterDesignOptions;
  merchConfiguration: MerchFooterConfiguration;

  //shopper theme
  shopperDesginOptions: ShopperFooterDesignOptions;
  shopperConfiguration: ShopperFooterConfiguration;

  //restaurants theme
  restaurantsDesginOptions: RestaurantsFooterDesignOptions;
  restaurantsConfiguration: RestaurantsFooterConfiguration;

  //anvogue theme
  anvogueDesginOptions: AnvogueFooterDesignOptions;
  anvogueConfiguration: AnvogueFooterConfiguration;
  //#endregion
  public platform: any;
  constructor(
    public addressAppService: AddressAppService,
    private customerServiceAppService: CustomerServiceAppService,
    private formBuilder: FormBuilder,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.currentTheme == 'ionic' || this.currentTheme == 'customizedIonic') {
      this.setIonicThemeOptions();
    } else if (this.currentTheme == 'merch') {
      this.setMerchThemeOptions();
    } else if (this.currentTheme == 'shopper') {
      this.setShopperThemeOptions();
    } else if (this.currentTheme == 'restaurants') {
      this.setRestaurantsThemeOptions();
    }
      else if (this.currentTheme == 'anvogue') {
      this.setAnvogueThemeOptions();
    }
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      footerColor: this.color,
      showCategoryListInFooter:
        this.storeDesignOptions?.showCategoryListInFooter,
      footerFrontColor: this.frontColor,
      footerHeadingColor: this.footerHeadingColor,
      hasSocialMediaLinks: this.hasSocialMediaLinks(),
      showNewsletterInFooter:
        this.storeOptions.enableNewsletter &&
        this.storeDesignOptions?.showNewsletterInFooter,
      isStoreBlogEnabled: this.storeOptions.isStoreBlogEnabled,
      showOurOutletsInFooter:
        this.storeAppService.isMultiOutletEnabled &&
        this.storeDesignOptions?.showOurOutletsInFooter,
      showPaymentCardsInFooter:
        this.storeDesignOptions?.showPaymenCardsInFooter,
      hideProviderInfo: this.storeOptions.hideProviderInfo,
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };

    this.configuration = {
      defaultClientLogoImage: this.storeAppService?.defaultClientLogoUrl,
      clientLogoImage: this.clientLogoUrl,
      logoUrl: "'/'",
      storeName: this.storeAppService.storeName,
      storeAbout: this.storeAppService.storeAbout,
      mobilePhone: this.customerServiceInfo?.mobilePhone, //this.storeConfig.mobilePhone,
      mobilePhoneCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.mobilePhone
      ), //this.getCallLink(this.storeConfig.mobilePhone),
      landline: this.customerServiceInfo?.landline, //this.storeConfig.landline,
      landlineCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.landline
      ), //this.getCallLink(this.storeConfig.landline),
      whatsApp: this.customerServiceInfo?.whatsApp, //this.storeConfig.whatsApp,
      whatsAppLink: this.customerServiceAppService.getWhatsAppLink(
        this.customerServiceInfo?.whatsApp
      ), //this.getWhatsAppLink(this.storeConfig.whatsApp),
      telegram: this.customerServiceInfo?.telegram, //this.storeConfig.telegram,
      telegramLink: this.customerServiceAppService.getTelegramLink(
        this.customerServiceInfo?.telegram
      ), //this.getTelegramLink(this.storeConfig.telegram),
      email: this.customerServiceInfo?.email, //this.storeConfig.email,
      instagram: this.socialMediaInfo?.instagram, //this.storeConfig.instagram,
      twitter: this.socialMediaInfo?.twitter, //this.storeConfig.twitter,
      facebook: this.socialMediaInfo?.facebook, //this.storeConfig.facebook,
      youtube: this.socialMediaInfo?.youtube, //this.storeConfig.youtube,
      snapchat: this.socialMediaInfo?.snapchat, //this.storeConfig.snapchat,
      ticktok: this.socialMediaInfo?.ticktok, //this.storeConfig.ticktok,
      linkedin: null, //this.storeConfig.linkedin,
      iOS: null, //this.storeConfig.iOS,
      iosImageUrl: this.iosImageUrl,
      android: null, //this.storeConfig.android,
      androidImageUrl: this.androidImageUrl,
      storeBlogUrl: this.getUrl('/blog'),
      outletsUrl: this.getUrl('/outlets'),
    };
  }

  setMerchThemeOptions() {
    this.merchDesginOptions = {
      footerColor: this.color,
      footerFrontColor: this.frontColor,
      footerIconsColor:this.footerIconsColor,
      showCategoryListInFooter:
        this.storeDesignOptions?.showCategoryListInFooter,
      footerHeadingColor: this.footerHeadingColor,
      hasSocialMediaLinks: this.hasSocialMediaLinks(),
      showNewsletterInFooter:
        this.storeOptions.enableNewsletter &&
        this.storeDesignOptions?.showNewsletterInFooter,
      isStoreBlogEnabled: this.storeOptions.isStoreBlogEnabled,
      showOurOutletsInFooter:
        this.storeAppService.isMultiOutletEnabled &&
        this.storeDesignOptions?.showOurOutletsInFooter,
      showPaymentCardsInFooter:
        this.storeDesignOptions?.showPaymenCardsInFooter,
      hideProviderInfo: this.storeOptions.hideProviderInfo,
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };

    this.merchConfiguration = {
      clientLogoImage: this.clientLogoUrl,
      logoUrl: this.getUrl('/'),
      storeName: this.storeAppService.storeName,
      storeAbout: this.storeAppService.storeAbout,
      mobilePhone: this.customerServiceInfo?.mobilePhone, //this.storeConfig.mobilePhone,
      mobilePhoneCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.mobilePhone
      ), //this.getCallLink(this.storeConfig.mobilePhone),
      landline: this.customerServiceInfo?.landline, //this.storeConfig.landline,
      landlineCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.landline
      ), //this.getCallLink(this.storeConfig.landline),
      whatsApp: this.customerServiceInfo?.whatsApp, //this.storeConfig.whatsApp,
      whatsAppLink: this.customerServiceAppService.getWhatsAppLink(
        this.customerServiceInfo?.whatsApp
      ), //this.getWhatsAppLink(this.storeConfig.whatsApp),
      telegram: this.customerServiceInfo?.telegram, //this.storeConfig.telegram,
      telegramLink: this.customerServiceAppService.getTelegramLink(
        this.customerServiceInfo?.telegram
      ), //this.getTelegramLink(this.storeConfig.telegram),
      email: this.customerServiceInfo?.email, //this.storeConfig.email,
      instagram: this.socialMediaInfo?.instagram, //this.storeConfig.instagram,
      twitter: this.socialMediaInfo?.twitter, //this.storeConfig.twitter,
      facebook: this.socialMediaInfo?.facebook, //this.storeConfig.facebook,
      youtube: this.socialMediaInfo?.youtube, //this.storeConfig.youtube,
      snapchat: this.socialMediaInfo?.snapchat, //this.storeConfig.snapchat,
      ticktok: this.socialMediaInfo?.ticktok, //this.storeConfig.ticktok,
      linkedin: null, //this.storeConfig.linkedin,
      iOS: null, //this.storeConfig.iOS,
      android: null, //this.storeConfig.android,
      storeBlogUrl: this.getUrl('/blog'),
      outletsUrl: this.getUrl('/outlets'),
      contactUsUrl: this.getUrl('/contact'),
    };
  }
  //shopper
  setShopperThemeOptions() {
    this.shopperDesginOptions = {
      footerColor: this.color,
      footerFrontColor: this.frontColor,
      showCategoryListInFooter:
        this.storeDesignOptions?.showCategoryListInFooter,
      footerHeadingColor: this.footerHeadingColor,
      addGradientInFooter: this.storeDesignOptions?.addGradientInFooter,
      hasSocialMediaLinks: this.hasSocialMediaLinks(),
      showNewsletterInFooter:
        this.storeOptions.enableNewsletter &&
        this.storeDesignOptions?.showNewsletterInFooter,
      isStoreBlogEnabled: this.storeOptions.isStoreBlogEnabled,
      showOurOutletsInFooter:
        this.storeAppService.isMultiOutletEnabled &&
        this.storeDesignOptions?.showOurOutletsInFooter,
      showPaymentCardsInFooter:
        this.storeDesignOptions?.showPaymenCardsInFooter,
      hideProviderInfo: this.storeOptions.hideProviderInfo,
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };

    this.shopperConfiguration = {
      clientLogoImage: this.clientLogoUrl,
      logoUrl: this.getUrl('/'),
      storeName: this.storeAppService.storeName,
      storeAbout: this.storeAppService.storeAbout,
      mobilePhone: this.customerServiceInfo?.mobilePhone, //this.storeConfig.mobilePhone,
      mobilePhoneCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.mobilePhone
      ), //this.getCallLink(this.storeConfig.mobilePhone),
      landline: this.customerServiceInfo?.landline, //this.storeConfig.landline,
      landlineCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.landline
      ), //this.getCallLink(this.storeConfig.landline),
      whatsApp: this.customerServiceInfo?.whatsApp, //this.storeConfig.whatsApp,
      whatsAppLink: this.customerServiceAppService.getWhatsAppLink(
        this.customerServiceInfo?.whatsApp
      ), //this.getWhatsAppLink(this.storeConfig.whatsApp),
      telegram: this.customerServiceInfo?.telegram, //this.storeConfig.telegram,
      telegramLink: this.customerServiceAppService.getTelegramLink(
        this.customerServiceInfo?.telegram
      ), //this.getTelegramLink(this.storeConfig.telegram),
      email: this.customerServiceInfo?.email, //this.storeConfig.email,
      instagram: this.socialMediaInfo?.instagram, //this.storeConfig.instagram,
      twitter: this.socialMediaInfo?.twitter, //this.storeConfig.twitter,
      facebook: this.socialMediaInfo?.facebook, //this.storeConfig.facebook,
      youtube: this.socialMediaInfo?.youtube, //this.storeConfig.youtube,
      snapchat: this.socialMediaInfo?.snapchat, //this.storeConfig.snapchat,
      ticktok: this.socialMediaInfo?.ticktok, //this.storeConfig.ticktok,
      linkedin: null, //this.storeConfig.linkedin,
      iOS: null, //this.storeConfig.iOS,
      android: null, //this.storeConfig.android,
      storeBlogUrl: this.getUrl('/blog'),
      outletsUrl: this.getUrl('/outlets'),
      contactUsUrl: this.getUrl('/contact'),
    };
  }

  //restaurants
  setRestaurantsThemeOptions() {
    this.restaurantsDesginOptions = {
      footerColor: this.color,
      footerFrontColor: this.frontColor,
      showCategoryListInFooter:
        this.storeDesignOptions?.showCategoryListInFooter,
      footerHeadingColor: this.footerHeadingColor,
      hasSocialMediaLinks: this.hasSocialMediaLinks(),
      showNewsletterInFooter:
        this.storeOptions.enableNewsletter &&
        this.storeDesignOptions?.showNewsletterInFooter,
      isStoreBlogEnabled: this.storeOptions.isStoreBlogEnabled,
      showOurOutletsInFooter:
        this.storeAppService.isMultiOutletEnabled &&
        this.storeDesignOptions?.showOurOutletsInFooter,
      showPaymentCardsInFooter:
        this.storeDesignOptions?.showPaymenCardsInFooter,
      hideProviderInfo: this.storeOptions.hideProviderInfo,
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };

    this.restaurantsConfiguration = {
      clientLogoImage: this.clientLogoUrl,
      logoUrl: this.getUrl('/'),
      storeName: this.storeAppService.storeName,
      storeAbout: this.storeAppService.storeAbout,
      mobilePhone: this.customerServiceInfo?.mobilePhone, //this.storeConfig.mobilePhone,
      mobilePhoneCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.mobilePhone
      ), //this.getCallLink(this.storeConfig.mobilePhone),
      landline: this.customerServiceInfo?.landline, //this.storeConfig.landline,
      landlineCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.landline
      ), //this.getCallLink(this.storeConfig.landline),
      whatsApp: this.customerServiceInfo?.whatsApp, //this.storeConfig.whatsApp,
      whatsAppLink: this.customerServiceAppService.getWhatsAppLink(
        this.customerServiceInfo?.whatsApp
      ), //this.getWhatsAppLink(this.storeConfig.whatsApp),
      telegram: this.customerServiceInfo?.telegram, //this.storeConfig.telegram,
      telegramLink: this.customerServiceAppService.getTelegramLink(
        this.customerServiceInfo?.telegram
      ), //this.getTelegramLink(this.storeConfig.telegram),
      email: this.customerServiceInfo?.email, //this.storeConfig.email,
      instagram: this.socialMediaInfo?.instagram, //this.storeConfig.instagram,
      twitter: this.socialMediaInfo?.twitter, //this.storeConfig.twitter,
      facebook: this.socialMediaInfo?.facebook, //this.storeConfig.facebook,
      youtube: this.socialMediaInfo?.youtube, //this.storeConfig.youtube,
      snapchat: this.socialMediaInfo?.snapchat, //this.storeConfig.snapchat,
      ticktok: this.socialMediaInfo?.ticktok, //this.storeConfig.ticktok,
      linkedin: null, //this.storeConfig.linkedin,
      iOS: null, //this.storeConfig.iOS,
      android: null, //this.storeConfig.android,
      storeBlogUrl: this.getUrl('/blog'),
      outletsUrl: this.getUrl('/outlets'),
      contactUsUrl: this.getUrl('/contact'),
    };
  }

  hasSocialMediaLinks(): boolean {
    return this.socialMediaInfo?.instagram ||
      this.socialMediaInfo?.twitter ||
      this.socialMediaInfo?.facebook ||
      this.socialMediaInfo?.youtube ||
      this.socialMediaInfo?.snapchat ||
      this.socialMediaInfo?.ticktok
      ? true
      : false;
  }

  hasPaymentCards(): boolean {
    return (
      this.storeAppService.paymentTypes?.mastercard ||
      this.storeAppService.paymentTypes?.visa ||
      this.storeAppService.paymentTypes?.payPal ||
      this.storeAppService.paymentTypes?.americanExpress ||
      this.storeAppService.paymentTypes?.klarna ||
      this.storeAppService.paymentTypes?.alipay ||
      this.storeAppService.paymentTypes?.cash ||
      this.storeAppService.paymentTypes?.bankTransfer ||
      this.storeAppService.paymentTypes?.mada ||
      this.storeAppService.paymentTypes?.fpx ||
      this.storeAppService.paymentTypes?.grabpay ||
      this.storeAppService.paymentTypes?.sofort ||
      this.storeAppService.paymentTypes?.giropay ||
      this.storeAppService.paymentTypes?.sepaDebit ||
      this.storeAppService.paymentTypes?.kNet
    );
  }

  public get footerCategories(): CustomCategoryStoreDto[] {
    const footerCategories = this.storeAppService.allCategories
      .filter((x) => x.showInFooter)
      .map((c) => {
        return {
          id: c.id,
          name: this.getValue(c),
          url: this.entityUtilityService.getCategoryUrl(c),
        } as CustomCategoryStoreDto;
      });
    return footerCategories;
  }

  public get footerPages(): StorePageStoreDto[] {
    return this.storeAppService.pages
      ? this.storeAppService.pages
          .filter((x) => x.showInFooter && x.type == StorePageType.Basic)
          .map((p) => {
            return {
              id: p.id,
              name: this.getValue(p),
              url: this.getUrl('/page/' + p.slug),
            } as CustomStorePageStoreDto;
          })
      : [];
  }

  public get footerBlogPages(): StorePageStoreDto[] {
    return this.storeAppService.pages
      ? this.storeAppService.pages
          .filter((x) => x.showInFooter && x.type == StorePageType.Blog)
          .map((p) => {
            return {
              id: p.id,
              name: this.getValue(p),
              url: this.getUrl('/blog/' + p.slug),
            } as CustomStorePageStoreDto;
          })
      : [];
  }

  public get paymentTypes(): StoreConfigPaymentTypesDto {
    return this.storeAppService?.paymentTypes;
  }

  setAnvogueThemeOptions(){
    this.anvogueDesginOptions = {
      footerColor: this.color,
      footerFrontColor: this.frontColor,
      footerIconsColor:this.footerIconsColor,
      newsLetterButtonColor: this.newsLetterButtonColor,
      showCategoryListInFooter:
        this.storeDesignOptions?.showCategoryListInFooter,
      footerHeadingColor: this.footerHeadingColor,
      hasSocialMediaLinks: this.hasSocialMediaLinks(),
      showNewsletterInFooter:
        this.storeOptions.enableNewsletter &&
        this.storeDesignOptions?.showNewsletterInFooter,
      isStoreBlogEnabled: this.storeOptions.isStoreBlogEnabled,
      showOurOutletsInFooter:
        this.storeAppService.isMultiOutletEnabled &&
        this.storeDesignOptions?.showOurOutletsInFooter,
        showOutletAddressInFooter:
        this.storeDesignOptions?.showOutletAddressInFooter,
      showPaymentCardsInFooter:
        this.storeDesignOptions?.showPaymenCardsInFooter,
      hideProviderInfo: this.storeOptions.hideProviderInfo,
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
      showPoweredByInFooter: this.storeDesignOptions?.showPoweredByInFooter,
    };

    this.anvogueConfiguration = {
      clientLogoImage: this.clientLogoUrl,
      logoUrl: this.getUrl('/'),
      storeName: this.storeAppService.storeName,
      storeAbout: this.storeAppService.storeAbout,
      mobilePhone: this.customerServiceInfo?.mobilePhone, //this.storeConfig.mobilePhone,
      mobilePhoneCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.mobilePhone
      ), //this.getCallLink(this.storeConfig.mobilePhone),
      landline: this.customerServiceInfo?.landline, //this.storeConfig.landline,
      landlineCallLink: this.customerServiceAppService.getCallLink(
        this.customerServiceInfo?.landline
      ), //this.getCallLink(this.storeConfig.landline),
      whatsApp: this.customerServiceInfo?.whatsApp, //this.storeConfig.whatsApp,
      whatsAppLink: this.customerServiceAppService.getWhatsAppLink(
        this.customerServiceInfo?.whatsApp
      ), //this.getWhatsAppLink(this.storeConfig.whatsApp),
      telegram: this.customerServiceInfo?.telegram, //this.storeConfig.telegram,
      telegramLink: this.customerServiceAppService.getTelegramLink(
        this.customerServiceInfo?.telegram
      ), //this.getTelegramLink(this.storeConfig.telegram),
      email: this.customerServiceInfo?.email, //this.storeConfig.email,
      instagram: this.socialMediaInfo?.instagram, //this.storeConfig.instagram,
      twitter: this.socialMediaInfo?.twitter, //this.storeConfig.twitter,
      facebook: this.socialMediaInfo?.facebook, //this.storeConfig.facebook,
      youtube: this.socialMediaInfo?.youtube, //this.storeConfig.youtube,
      snapchat: this.socialMediaInfo?.snapchat, //this.storeConfig.snapchat,
      ticktok: this.socialMediaInfo?.ticktok, //this.storeConfig.ticktok,
      linkedin: null, //this.storeConfig.linkedin,
      iOS: null, //this.storeConfig.iOS,
      android: null, //this.storeConfig.android,
      storeBlogUrl: this.getUrl('/blog'),
      outletsUrl: this.getUrl('/outlets'),
      contactUsUrl: this.getUrl('/contact'),
    };
  }
}
